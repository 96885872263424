$(function () {
  $("body").addClass("ready");

  // Modify contents
  // $("form.ccpa").html(function(buscayreemplaza, reemplaza) {
  //     return reemplaza.replace('<div class="field-group" role="group" aria-labelledby="customertypes">', '<div class="field-group" role="group"" aria-labelledby="customer types">');
  // });
  // $("form.ccpa").html(function(buscayreemplaza, reemplaza) {
  //     return reemplaza.replace('<div class="field-group" role="group" aria-labelledby="requesttypes">', '<div class="field-group" role="group" aria-labelledby="request types">');
  // });
  // $("form.ccpa").html(function(buscayreemplaza, reemplaza) {
  //     return reemplaza.replace('<div class="form-entry gdpr-wrapper col-sm-10 col-sm-offset-1" role="group" aria-labelledby="gdpr-checkboxes-">', '<div class="form-entry gdpr-wrapper col-sm-10 col-sm-offset-1" role="group" aria-labelledby="gdpr checkboxes">');
  // });

  //ADA IS NOT WORKING
  // $("#default").html(function(buscayreemplaza, reemplaza) {
  //     return reemplaza.replace('<div class="navigation overlay open">', '<div class="navigation overlay open" role="navigation">');
  // });
  // $("#footer .footer-top").html(function(buscayreemplaza, reemplaza) {
  //     return reemplaza.replace('<nav id="secondary-nav" class="secondary-navigation" role="navigation" aria-label="Secondary">', '<nav id="secondary-nav" class="secondary-navigation" role="navigation">');
  // });

  document
    .getElementsByClassName("navigation")[0]
    .setAttribute("role", "navigation");
  document.getElementById("secondary-nav").setAttribute("role", "navigation");

  /*BOOKING WIDGET
   ****************************************************************/
  $(".overlay.navigation").prepend(
    '<div class="inner top"><a href="' +
      $(".footer-btn.last").attr("href") +
      '" class="res-btn bookNow" target="_blank">Check Availability</a></div>'
  );

  $("#content .notice p").slideUp();
  $("#content .notice .title").click(function (event) {
    if ($(this).hasClass("clicked")) {
      $(this).removeClass("clicked");
      $("#content .notice p").slideUp();
    } else {
      $(this).addClass("clicked");
      $("#content .notice p").slideDown();
    }
  });

  $("#landing-page.adara a").each(function () {
    $(this).attr("target", "_blank");
  });

  /*MOBILE MENU NAV
   ****************************************************************/
  $(".secondary-nav-4>a").attr("href", site_url + "m/social");
  $("#room1868 .copy-holder").append(
    '<span class="amenites-trigger2" style="width: 155px;"><a href="/accessibility" ><span>Visit ADA Policy Page</span></a></span>'
  );

  $(".close-res").click(function (event) {
    event.preventDefault();
    $("html").removeClass("hide-overflow");

    $(".booking-widget")
      .stop()
      .animate({ left: "100%" }, { queue: false, duration: 300 });
  });

  /*MOBILE MENU NAV
   ****************************************************************/

  //Open Navigation
  $("#menu-btn").click(function (event) {
    $("html").addClass("hide-overflow");
    $(".navigation").addClass("open");
    $(".navigation").stop().animate({ left: "0px" }, { duration: 300 });
    if ($(".navigation ul li a").hasClass(".item-open")) {
      $(".item-open").get(0).scrollIntoView(false);
    }
  });

  $(".close-menu").click(function (event) {
    event.preventDefault();
    $("html").removeClass("hide-overflow");
    $(".navigation").removeClass("open");
    $(".navigation")
      .stop()
      .animate({ left: "100%" }, { queue: false, duration: 300 });
  });
  //SUBNAVIGATION

  // Open active sub-nav on load
  $(".navigation ul.submenu li .active").closest(".inner").addClass("open");
  $(".navigation ul.submenu li .active")
    .parents()
    .parents("li")
    .addClass("item-open");
  $(".navigation ul.submenu li .active")
    .closest(".sub-sub-menu")
    .slideDown(300, function () {})
    .addClass("menu-open");

  //Toggle open/close on tap
  $(".navigation li a")
    .off("click")
    .on("click", function (event) {
      if ($(this).parent().children(".sub-sub-menu").length > 0) {
        event.preventDefault();

        if ($(this).next().hasClass("menu-open")) {
          $(this).closest(".inner").removeClass("open");
          $(".navigation ul li").removeClass("item-open");
          $(this).parent().addClass("item-open");
          $(this)
            .next()
            .slideUp(300, function () {
              if ($(".navigation ul li a").hasClass("active")) {
                $(".item-open").get(0).scrollIntoView(false);
              }
            })
            .removeClass("menu-open");
        } else {
          $(this).closest(".inner").addClass("open");
          $(".navigation ul li").removeClass("item-open");
          $(this).parent().addClass("item-open");
          $(".sub-sub-menu.menu-open").slideUp(300).removeClass("menu-open");
          $(this)
            .next()
            .addClass("menu-open")
            .slideDown(300, function () {
              if ($(".navigation ul li a").hasClass("active")) {
                $(".item-open").get(0).scrollIntoView(false);
              }
            });
        }
      }
    });

  /*CUSTOM SCRIPTS FOR THIS SITE
   ****************************************************************/
  if ($("body.sitemap").length > 0) {
    $(
      "#content > div.content > section > div:nth-child(2) > ul > li:nth-child(7) > ul > li:nth-child(2)"
    ).hide();
  }

  //Slick Slider
  $(".sym-slides").slick();
  $(".press-new-carousel").slick({
    accessibility: false,
    adaptiveHeight: true,
  });

  //Slick slider locations neighbours
  $(".info-pins").slick();

  $(".slide-coffe").slick({
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    adaptiveHeight: true,
  });

  $(".fancybox").fancybox({
    padding: 0,
  });
  var pathname = window.location.href;
  if (pathname.split("m/")[1] == "weddings") {
    $("table").stacktable();
  }

  $("p").each(function (index, item) {
    if ($.trim($(item).text()) === "") {
      $(item).slideUp(); // $(item).remove();
    }
  });

  $(".book-direct .direct-headline").click(function () {
    $(this).toggleClass("open");
    $("div.arrow-head").toggleClass("rotate-arrow");
    setTimeout(function () {
      $(".book-direct .copy").toggleClass("open");
    }, 500);
  });

  //Tabs
  $(".tabs-block__filter ul li:nth-child(2)").click();
  $(".tabs-block__filter ul li:nth-child(2)").addClass("active");
  $(".tabs-block__filter ul li").click(function () {
    const thisTag = $(this).attr("data-tag");
    if (thisTag == "all") {
      $(".tabs-block--filter .tabs-block__col").hide();
      $(".tabs-block--filter .tabs-block__col").fadeIn();
      $(".tabs-block__filter ul li").removeClass("active");
      $(this).addClass("active");
    } else {
      $(".tabs-block--filter .tabs-block__col").removeClass("active");
      $(".tabs-block--filter .tabs-block__col").hide();
      $(".tabs-block--filter ." + thisTag).fadeIn();
      $(".tabs-block__filter ul li").removeClass("active");
      $(this).addClass("active");
    }
  });

  //Acts when scroll down page.
  $(window).scroll(function (event) {
    //scrollDown();
    var top = $(document).scrollTop();
    var subtop = $("#header-img").height() - $("header").height();
    if (top > 0) {
      $("header").addClass("scroll", 200);
    } else {
      $("header").removeClass("scroll", 200);
    }
    if ($(this).scrollTop() > subtop) {
      $(".submenu-stick").addClass("navbar-fixed");
    } else {
      $(".submenu-stick").removeClass("navbar-fixed");
    }
    if ($("#home").length > 0) {
      if (top > 80) {
        $(".notice-payment").fadeOut();
      } else {
        $(".notice-payment").fadeIn();
      }
    }
  });

  $(".all-reasons").slick({
    arrows: true,
    dots: true,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 500,
    fade: true,
    accessibility: false,
    //centerMode: true,
    //centerPadding: '20px',
    prevArrow: $(".prev-reason"),
    nextArrow: $(".next-reason"),
    appendDots: $(".reasons-nav"),
    customPaging: function (slider, i) {
      var thumb = $(slider.$slides[i]).data();
      return "0" + (i + 1);
    },
  });

  $(".all-specials").slick({
    arrows: true,
    dots: false,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 500,
    fade: true,
    accessibility: false,
    //centerMode: true,
    //centerPadding: '20px',
    prevArrow: $(".prev-special"),
    nextArrow: $(".next-special"),
  });

  $(".all-gallery").slick({
    arrows: true,
    dots: false,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 500,
    fade: false,
    accessibility: false,
  });

  $(".dining-trigger").click(function () {
    if ($(this).hasClass("open")) {
      $("#menusContainer").css("height", "0");
    } else {
      $("#menusContainer").css("height", "auto");
    }
    $(this).toggleClass("open");
    var text = $(this).text();
    $(this).text(text == "View Our Menus" ? "View Our Menus" : "Show Less");
  });
  $("#room1962 .amenites-trigger").text("");
  $("#room1962 .amenites-trigger").append(
    '<a href="/accessibility"><span>Visit ADA Policy Page</span></a>SEE AMENITIES'
  );
  $(".amenites-trigger").click(function (explore) {
    $(this).closest("div").find(".full-amenities").slideToggle();
    $(this).toggleClass("open");
    var text = $(this).text();
    if ($(this).parent().parent().parent().parent().attr("id") != "room1962") {
      $(this).text(text == "Show Less" ? "Read More" : "Show Less");
    } else {
      if ($(this).hasClass("open")) {
        $("#room1962 .amenites-trigger").text("");
        $("#room1962 .amenites-trigger").append(
          '<a href="/accessibility"><span>Visit ADA Policy Page</span></a>Show Less'
        );
      } else {
        $("#room1962 .amenites-trigger").text("");
        $("#room1962 .amenites-trigger").append(
          '<a href="/accessibility"><span>Visit ADA Policy Page</span></a>SEE AMENITIES'
        );
      }
    }
  });
  $(".amenites-meeting").click(function (explore) {
    $(this).closest("div").find(".full-amenities").slideToggle();
    $(this).toggleClass("open");
    var text = $(this).text();
    $(this).text(text == "Show Less" ? "Read More" : "Show Less");
  });
  $(".amenites-meeting-action2").click(function (explore) {
    $(this).closest("div").find(".full-amenities2").slideToggle();
    $(this).toggleClass("open");
    var text = $(this).text();
    $(this).text(text == "Show Less" ? "Read More" : "Show Less");
  });

  setTimeout(function () {
    $(".comparison-chart").addClass("hide");
  }, 500);

  $(".room-comparison").click(function (explore) {
    $(this).closest("div").find(".comparison-chart").slideToggle();
    $(this).toggleClass("open");
    $(this).closest("div").find(".comparison-chart").removeClass("hide");
  });

  $(".tabs-menu a").click(function (event) {
    event.preventDefault();
    $(".tabs-menu a").removeClass("active");
    $(this).addClass("active");
    var tab = $(this).attr("href");
    $(".tab-content").not(tab).css("display", "none");
    $(tab).fadeIn();
    $(".sym-slides").slick("unslick");
    $(".sym-slides").slick();
  });

  // Add body class singup-overlay button
  let btnSign = document.getElementById("js-signup");
  let formSign = document.getElementById("signup-popup");
  let stayConnected = document.getElementById("stay-connected");
  let body = document.getElementsByTagName("body")[0];

  if( btnSign ) { 
    btnSign.addEventListener("click", function (event) {
      event.preventDefault();
      formSign.style.display = "block";
      stayConnected.style.display = "none";
    });
  }
  
  //SignUp Form Display
  let signUpCTA = document.getElementById("signupCtaMobile");
  let signUpForm = document.getElementById("js-header-signup");

  if(signUpCTA && signUpForm) {    
    signUpCTA.addEventListener('click', () => {
      document.getElementById("signupContainer").classList.add("open");
    });

    document.getElementById("js-header-signup").addEventListener("click", function () {
      document.getElementById("signupContainer").classList.remove("open");
    });
  } 

    //PAGE SCROLL FUNCTIONALITY
    function scrollElementByQuadParam(url) {
    const parsedURL = new URL(url);
    const quadValue = parsedURL.searchParams.get("quad");
  
    if (quadValue) {
      const elementToScroll = document.getElementById(quadValue);
  
      if (elementToScroll) {
        const scrollToPosition = elementToScroll.getBoundingClientRect().top - 100;
  
        // Scroll to the calculated position
        window.scrollTo({
          top: window.scrollY + scrollToPosition,
          behavior: "smooth"
        });
  
      } else {
        console.log("No element with the provided 'quad' parameter value was found.");
      }
    } else {
      console.log("The 'quad' parameter is not present in the URL.");
    }
    }
    scrollElementByQuadParam( window.location.href );
    

  /*END OF CUSTOM SCRIPTS
   ****************************************************************/
});

if (document.getElementById("ot-sdk-btn") && document.getElementById("tertiary-navigation")) {
  document.getElementById("tertiary-navigation").appendChild(document.getElementById("ot-sdk-btn"))
}

function Gratitude() {
  document.getElementsByClassName("text-top")[0].classList.add("submitted");
}

/**
 * Generated OpenTable.com code for the Book a Table widget.
 * @param {string} boxid The ID of the DIV tag in which the widget will be displayed.
 * @param {boolean} iframe Specify true to have the widget displayed in an IFRAME.
 */

function generatedOpenTable(
  boxid,
  type = "standard",
  theme = "wide",
  iframe = false,
  newtab = false
) {
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  // script.src = `//www.opentable.com/widget/reservation/loader?rid=991165&type=${type}&theme=${theme}&iframe=${iframe}&domain=com&lang=en-US&newtab=${newtab}`;
  script.src = `//www.opentable.com/widget/reservation/loader?rid=991165&type=${type}&theme=${theme}&iframe=${iframe}&domain=com&lang=en-US&newtab=${newtab}&ot_source=Restaurant%20website`;

  let first = document.getElementById(boxid);
  if (first !== null) {
    first.appendChild(script);

    return true;
  }

  return false;
}